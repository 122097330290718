/* loginForm.css */
 
  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  

  .container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  p {
    margin-top: 10px;
    color: red;
  }
  
  .success-message {
    margin-top: 10px;
    color: green;
  }

  .text{
    color: black;
    text-align: center;
    font-size: 30px;
    font-family: 'Satisfy', cursive;
    
}

